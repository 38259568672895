import React from 'react';
import { Link } from 'gatsby';
import { edgeTest } from '../utils/helpers';
import { decodeEntities } from "../utils/htmlParse";

export default class GatsbyLink extends React.Component {

  render() {
    const {to, activeClassName = "active", children, data, decode = false, ...other } = this.props

    if ( to && to.startsWith('#') ) {
      return <span onClick={event => this.scrollIntoView(event, to)} {...other}>{decode ? decodeEntities(children) : children}</span>
    }

    if (to && to.startsWith('/') && edgeTest()) {
      return <Link activeClassName={activeClassName} {...other} to={to}>{decode ? decodeEntities(children) : children}</Link>
    }

    return (
      <a href={to} {...other}>{decode ? decodeEntities(children) : children}</a>
    )
  }
}