import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';

class RenderContent extends Component {
  render() {
    const { content = '', excerpt = false, data} = this.props;
    const { wordpressUrl, siteUrl } = data.site.siteMetadata;
    const { render_content = excerpt ? content.split('<!--more-->')[0] : content } = content;
    var new_content = render_content ? render_content.replace('<iframe', '<div class="outer-video"><div class="video"><iframe') : '';
    var new_content = new_content ? new_content.replace('</iframe>', '</iframe></div></div>') : '';
    var new_content = new_content ? new_content.replace(new RegExp(wordpressUrl,'g'), '').replace(new RegExp(siteUrl,'g'), '') : new_content;

    return (
      <div
          className={this.props.className ? this.props.className : "entry-content"}
          dangerouslySetInnerHTML={{__html: decodeEntities(new_content)}}
      />
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            wordpressUrl,
            siteUrl
          }
        }
      }
    `}
    render={data => <RenderContent data={data} {...props} />}
  />
)
