import React from 'react'
import { Link } from 'gatsby'
import LogoSVG from '../../images/LYCO_L_RGB.svg'
import './Logo.scss'

export const Logo = props => {
  const { url} = props
  return (
    <div className="logo-container">
      <Link
        to={url}
        //   onClick={() => closeNavigation()}
      ><img src={LogoSVG} /></Link>
    </div>
  )
}
