import React, { Component } from 'react';
// import { StaticQuery, graphql } from 'gatsby';
import './NewsletterSignup.scss';

export const NewsletterSignup = props => {
  const { url } = props
    return (
      <section className="NewsletterSignup">
        <div className="wrapper">
          <div className="flex-row">
            <div className="text">
              <h2 className="title">Newsletter heading to go here</h2>
              <p className="subtitle">Sign up to our newsletter copy to go here</p>
            </div>
            <div className="form">
              <form action="#">
                <input className="email-input" type="email" placeholder="Your email" />
                <div className="button-container">
                  <button type="submit"></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
