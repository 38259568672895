import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import '../sass/global/styles.scss'
import { Header, Footer } from './Common'
import { SiteNotice } from '../components/SiteNotice'

const Layout = ({ location, children, className }) => {
  const [showWidget, setShowWidget] = useState(false)
  const [pathAndQuery, setPathAndQuery] = useState('')
  const [currentLang, setCurrentLang] = useState('')

  const hostName = 'lycopodium.com'

  if (typeof window !== 'undefined') {
    /* Possibly l;ook to change */
    useEffect(() => {
      //Wait till the script has loaded and then change the hreflang links
      setTimeout(() => {
        const widget = document.querySelector('#weglot-switcher-1')
        if (widget) {
          setShowWidget(true)
          setPathAndQuery(window.location.pathname + window.location.search)
          setCurrentLang(window.location.host)
        }
      }, 1000)
    }, [window.location])
  }
  return (
    <>
      <SiteNotice />
      <div id="layout">
        <Helmet title="Home | Lycopodium" />
        <span id="top"></span>
        <Header location={location} />
        <main className={className ? className : ''}>{children}</main>
        <Footer location={location} />
      </div>
      {showWidget && (
        <div className="weglot-switcher" data-wg-notranslate="true">
          <a
            href={`https://es.${hostName}${pathAndQuery}`}
            className={currentLang === `es.${hostName}` ? 'active' : ''}
          >
            Español
          </a>
          <a
            href={`https://www.${hostName}${pathAndQuery}`}
            className={currentLang === `www.${hostName}` ? 'active' : ''}
          >
            English
          </a>
        </div>
      )}
    </>
  )
}

export default Layout
