import React from 'react'
import './CookiePolicy.scss'
import RenderContent from '../RenderContent'
import ls from 'local-storage'

export default class CookiePolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notice: ls.get('cookieNotice') ? ls.get('cookieNotice') : false
    }
  }
  closeCookieNotice = () => {
    ls.set('cookieNotice', true)
    this.setState({ notice: true })
  }
  render () {
    const { content } = this.props
    const { notice } = this.state
    if (notice) return null
    return (
      <div className="cookie-policy">
        <div className="inner" onClick={() => this.closeCookieNotice()}>
          <h3>Notice</h3>
          <RenderContent content={content}/>
          <span className="close">Accept</span>
        </div>
      </div>
    )
  }
}
