import React from 'react'
import './MenuButton.scss'

export const MenuButton = props => {
  const { toggleMenu, navOpen } = props
  return (
    <button className={`menu-button ${navOpen ? 'nav-open' : ''}`} onClick={toggleMenu}>
    <div className="line line-1"></div>
      <div className="line line-2"></div>
      <div className="line line-3"></div>
    </button>
  )
}
